<template>
  <ContentTitle msg="SMS"/>
  <div class="smsView">
    <div class="operations">
      <a-button type="primary" @click="handleAdd">+ Add</a-button>
      <div>
        <span class="marginLeft">ClientName</span>
        <a-select
            v-model:value="selectClientName"
            :options="clientNameOptions"
            placeholder="Select"
            class="marginLeft"
            style="width: 120px;"/>
        <a-button type="primary" class="marginLeft" @click="handleQuery">Query</a-button>
        <a-button type="primary" class="marginLeft" @click="handleQuery">Clear</a-button>
      </div>
    </div>
    <div class="tables">
      <a-table
          :data-source="convertedDataSource(dataSource)"
          :columns="columns"
          size="small"
          emptyText="No Data"
          :pagination="false"
          :loading="loading"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'status'">
            <span>
              {{ record.status === 1 ? 'Normal' : '' }}
            </span>
          </template>
          <template v-else-if="column.dataIndex === 'operation'">
            <a @click="handleEdit(record)">Edit</a>
            <a-popconfirm
                v-if="dataSource.length"
                title="Are you sure to delete the current data?"
                @confirm="handleDelete(record)">
              <a class="marginLeft">Delete</a>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
    </div>
    <div class="paginationArea">
      <div class="pageTotal">Showing {{ currentPage * pageSize > totalNumber ? totalNumber : currentPage * pageSize }} of {{ totalNumber }}</div>
      <a-pagination v-model:current="currentPage" v-model:pageSize="pageSize" :total="totalNumber" :show-size-changer="false">
        <template #itemRender="{ type, originalElement }">
          <a v-if="type === 'prev'"> <left-outlined/> Previous</a>
          <a v-else-if="type === 'next'">Next <right-outlined /></a>
          <component :is="originalElement" v-else></component>
        </template>
      </a-pagination>
    </div>

    <a-modal
        v-model:visible="visible"
        title="Template Set"
        :mask-closable="false"
        :centered="false"
        :confirm-loading="confirmLoading"
        ok-text="Save"
        cancel-text="Cancel"
        :ok-button-props="{ size: 'large' }"
        :cancel-button-props="{ size: 'large' }"
        width="600px"
        @ok="handleOk"
        @cancel="handleCancel">
      <template #closeIcon>
        <close-icon/>
      </template>
      <a-form ref="formRef" layout="vertical" :model="formState" :rules="rules">
        <a-form-item has-feedback label="Template Name" name="templateName">
          <a-input v-model:value="formState.templateName" type="text" autocomplete="off" placeholder="Template Name"/>
        </a-form-item>
        <a-form-item has-feedback label="Message Mode" name="mode">
          <a-radio-group v-model:value="formState.mode">
            <a-radio-button value="Notice">Notice</a-radio-button>
            <a-radio-button value="Link">Link</a-radio-button>
            <a-radio-button value="Control">Control</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item has-feedback label="Tags" name="tags">
          <a-checkable-tag
              v-for="(tag, index) in tagsData"
              :key="tag"
              v-model:checked="selectTags[index]"
              @change="checked => handleTagsChange(tag, checked)"
          >
            {{ tag }}
          </a-checkable-tag>
        </a-form-item>
        <a-form-item has-feedback label="Content" name="content">
          <a-textarea v-model:value="formState.content" disabled :rows="4" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref, watch} from "vue";
import {addTemplateAjax, deleteTemplateAjax, editTemplateAjax, getTemplateListAjax} from "@/api/template";
import CloseIcon from "@/components/icon/CloseIcon.vue";
import {message} from "ant-design-vue";
import ContentTitle from "@/components/ContentTitle.vue";
import { LeftOutlined, RightOutlined } from "@ant-design/icons-vue"
import {convertUTCToLocal} from "@/hooks/dateFormat";

const selectClientName = ref('')
const loading = ref(false);
const confirmLoading = ref(false);
const clientNameOptions = ref([])
const dataSource = ref([])
const columns = ref([
  {
    title: 'Name',
    dataIndex: 'templateName',
    key: 'templateName',
  },
  {
    title: 'TemplateId',
    dataIndex: 'templateId',
    key: 'templateId',
  },
  {
    title: 'Content',
    dataIndex: 'content',
    key: 'content',
  },
  {
    title: 'MessageMode',
    dataIndex: 'messageMode',
    key: 'messageMode',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'AddDate',
    dataIndex: 'addDate',
    key: 'addDate',
  },
  {
    title: 'ModifyDate',
    dataIndex: 'modifyDate',
    key: 'modifyDate',
  },
  {
    title: 'Operation',
    dataIndex: 'operation',
    key: 'operation',
    align: 'center'
  }
])
const currentPage = ref(1)
const totalNumber = ref(0)
const pageSize = ref(10)
const visible = ref(false);
const tagsData = reactive(['{code}', '{signature}', '{url}', '{event}']);
const selectTags = ref([false, false, false, false]);
const contentHolder = 'Your verification code is ';

const formRef = ref();
const formState = reactive({
  templateName: '',
  mode: '',
  tags: [],
  content: contentHolder,
  templateId: ''
})

let validateName = async (_rule, value) => {
  if (value === '') {
    return Promise.reject('Please input the template name.')
  } else {
    return Promise.resolve()
  }
}

let validateMode = async (_rule, value) => {
  if (value === '') {
    return Promise.reject('Please select the message mode.')
  } else {
    return Promise.resolve()
  }
}

let validateTags = async (_rule, value) => {
  if (value.length === 0) {
    return Promise.reject('Please select the tags.')
  } else {
    return Promise.resolve()
  }
}

const rules = {
  templateName: [{
    required: true,
    validator: validateName,
    trigger: 'change',
  }],
  mode: [{
    required: true,
    validator: validateMode,
    trigger: 'change',
  }],
  tags: [{
    required: true,
    validator: validateTags,
    trigger: 'change',
  }],
  content: [{
    required: true,
    trigger: 'change',
  }],
}

const getData = () => {
  loading.value = true;
  getTemplateListAjax(currentPage.value, pageSize.value, '', 'SMS').then(res => {
    console.log(res)
    dataSource.value = res.data.list;
    loading.value = false;
    totalNumber.value = res.data.totalNum;
  }).catch(err => {
    console.log(err);
    loading.value = false;
  })
}

const convertedDataSource = dataSource => {
  return dataSource?.map(item => ({
    ...item,
    modifyDate: convertUTCToLocal(item.modifyDate),
    addDate: convertUTCToLocal(item.addDate),
  }));
};

const handleOk = () => {
  formRef.value.validate().then(() => {
    confirmLoading.value = true
    //在此处使用setTimeout做一定的延迟来模拟请求后端api
    const data = {
      clientId: '',
      templateId: formState.templateId,
      content: formState.content,
      messageMode: formState.mode,
      messageType: 'SMS',
      tags: formState.tags.join(', '),
      templateName: formState.templateName
    };
    console.log(data);

    if(formState.templateId) {
      editTemplateAjax(data).then(res => {
        console.log(res);
        visible.value = false
        confirmLoading.value = false
        message.success('update template sms successfully.')
        selectTags.value = [false, false, false, false];
        getData();
      }).catch(err => {
        visible.value = false
        confirmLoading.value = false
        console.log(err);
      })
    } else {
      addTemplateAjax(data).then(res => {
        console.log(res);
        visible.value = false
        confirmLoading.value = false
        message.success('add template sms successfully.')
        selectTags.value = [false, false, false, false];
        getData();
      }).catch(err => {
        visible.value = false
        confirmLoading.value = false
        console.log(err);
      })
    }
  }).catch(error => {
    console.log('error', error)
  })
}
const handleTagsChange = (tag, checked) => {
  console.log(tag, checked)
  if (checked) {
    formState.tags.push(tag);
  } else {
    const index =  formState.tags.indexOf(tag);
    if (index !== -1) {
     formState.tags.splice(index, 1);
    }
  }
  if (formState.tags.length > 0) {
    formState.content = contentHolder + formState.tags.join(" , ");
  } else {
    formState.content = contentHolder;
  }
  formRef.value.validate();
};

const handleCancel = () => {
  formRef.value.resetFields();
  selectTags.value = [false, false, false, false];
}

const handleQuery = () => {
  currentPage.value = 1;
  getData();
}

const handleDelete = (item) => {
  console.log(item);
  if(item.templateId) {
    deleteTemplateAjax(item.templateId).then(res => {
      console.log(res);
      getData();
    }).catch(err => {
      console.log(err);
    })
  }
}

const handleEdit = (item) => {
  formState.templateName = item.templateName;
  formState.mode = item.messageMode;
  const newArrayList = item.tags.split(',');
  formState.tags = [];
  newArrayList.forEach(c => {
    formState.tags.push(c.toString().trim());
  })
  formState.templateId = item.templateId;
  const isCode = item.tags.toString().indexOf("{code}") !== -1;
  const isSignature = item.tags.toString().indexOf("{signature}") !== -1;
  const isUrl = item.tags.toString().indexOf("{url}") !== -1;
  const isEvent = item.tags.toString().indexOf("{event}") !== -1;
  selectTags.value = [isCode, isSignature, isUrl, isEvent];
  if (formState.tags.length > 0) {
    formState.content = contentHolder + formState.tags.join(" , ");
  } else {
    formState.content = contentHolder;
  }
  visible.value = true;
  console.log(item)
}

const handleAdd = () => {
  formState.templateName = "";
  formState.mode = '';
  formState.tags = [];
  formState.content = contentHolder;
  formState.templateId = null;
  visible.value = true;
  console.log(visible.value)
}

watch(currentPage, () => {
  getData();
})

onMounted(() => {
  getData();
})
</script>
<style scoped lang="scss">
.smsView {

}
</style>