<template>
  <ContentTitle msg="404" v-if="false"/>
  <a-alert :message="languageResources.HA_LBL_NotFoundPage" type="error" show-icon v-if="false">
  </a-alert>
  <a-result status="404" title="404" sub-title="Sorry, the page you visited does not exist.">
    <template #extra>
      <a-button v-if="false" type="primary">Back Home</a-button>
    </template>
  </a-result>
</template>

<script>
import {defineComponent} from "vue"
import ContentTitle from "@/components/ContentTitle.vue"
import store from "@/store"

export default defineComponent ({
  name: '404View',
  components: {
    ContentTitle
  },
  setup() {
    return {
      languageResources: store.state.languageResources
    }
  }
})
</script>
