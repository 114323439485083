import axios from "axios"
import {message} from 'ant-design-vue'
import _ from 'lodash'
import {serverConfig} from "@/hooks/serverConfig"
import store from "@/store";

const request = axios.create({
    baseURL: process.env.NODE_ENV !== 'production' ? '' : serverConfig.baseUrl,
    headers: {
        common: {
            Authorization: 'Bearer ' + localStorage.getItem("token")
        }
    },
    contentType: 'application/json',
    timeout: 30 * 1000
})

request.interceptors.request.use(config => {
    return config
}, error => {
    return Promise.reject(error)
})
request.interceptors.response.use(response => {
    const data = response.data
    const status = response.status
    if (status !== 200 && status !== 202) {
        message.error(response.statusText).then(r => console.log(r));
        console.log(response.statusText)
        return Promise.reject(data)
    }
    if (/attachment/.test(response.headers['content-disposition']) || /PDF/.test(response.headers['content-type'])) {
        return response
    }
    else if (data.code > 10200) {
        console.log(data)
        message.error(data.message ? data.message : data.msg).then(r => console.log(r));
        return Promise.reject(data)
    } else if (data.code !== 200 && data.code !== 202 && data.code !== 10200) {
        console.log(data)
        message.error(data.message ? data.message : data.msg).then(r => console.log(r));
        if (data.code === 401) {
            store.dispatch('signOut')
        } else {
            return Promise.reject(data)
        }
    }
    return data
}, error => {
    return Promise.reject(error)
})

export default request

// update axios's token
export function updateRequestHeaders(opt) {
    _.map(opt, (value, key) => {
        request.defaults.headers.common[key] = value;
    })
}