import axios from "@/axios"

/**
 * get access menu
 * @function getAccessMenuAjax
 * @returns {promise} axios
 */
export function getAccessMenuAjax(moduleName) {
    return axios({
        method: 'get',
        url: 'api/cccplatform/v1/menu/access-menus?appName=' + moduleName
    })
}

/**
 * get user info
 * @function getUserInfoAjax
 * @returns {promise} axios
 */
export function getUserInfoAjax() {
    return axios({
        method: 'get',
        url: 'api/cccaccount/v1/user/userInfo'
    })
}

/**
 * get account info
 * @function getAccountInfoAjax
 * @returns {promise} axios
 */
export function getAccountInfoAjax() {
    return axios({
        method: 'get',
        url: 'api/get-account'
    })
}
