<template>
  <div class="container">
    <ContentTitle msg="Message Query"/>
    <div class="queryContainer">
      <a-form :layout="formState.layout" :model="formState">
        <a-row :gutter="32">
          <a-col :span="6" class="formItemColumn">
            <a-form-item label="Time Select">
              <a-range-picker v-model:value="timeRangeValue"/>
            </a-form-item>
          </a-col>
          <a-col :span="6" class="formItemColumn">
            <a-form-item label="MessageType">
              <a-select
                  ref="select"
                  v-model:value="formState.messageType"
                  :options="messageTypeOptions">
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6" class="formItemColumn">
            <a-form-item label="KeyWords">
              <a-input v-model:value="formState.keyWords" placeholder="Input"/>
            </a-form-item>
          </a-col>
          <a-col :span="6" class="formItemColumn">
            <a-form-item label="Total">
              <a-input v-model:value="pagination.total" disabled/>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="32">
          <a-col :span="6" class="formItemColumn">
            <a-form-item label="Success">
              <a-input v-model:value="formState.success" disabled/>
            </a-form-item>
          </a-col>
          <a-col :span="6" class="formItemColumn">
            <a-form-item label="Failure">
              <a-input v-model:value="formState.failure" disabled/>
            </a-form-item>
          </a-col>
          <a-col :span="6" class="formItemColumn">
            <a-form-item label="ClientName">
              <a-select
                  ref="select"
                  v-model:value="formState.clientName"
                  :options="clientNameList">
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6" class="formItemColumn">

          </a-col>
        </a-row>

        <div class="btnContainer">
          <a-button ghost @click="handleSearch">
            <template #icon>
              <SearchOutlined/>
            </template>
            Search
          </a-button>
          <a-button type="primary" class="btnMargin" @click="handleSend">
            <template #icon>
              <SendOutlined/>
            </template>
            Send
          </a-button>&nbsp;&nbsp;
        </div>
      </a-form>
    </div>

    <a-table :columns="columns" :data-source="convertedDataSource(data)" :pagination="pagination" :loading="loading"
             @change="handleTableChange">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'operation'">
          <a @click="handleTrackMessage(record)">Track</a>
        </template>
      </template>
    </a-table>
    <a-modal
        v-model:visible="trackModalVisible"
        title="Query"
        :mask-closable="false"
        :centered="true"
        :footer="null"
        width="820px">
      <template #closeIcon>
        <close-icon/>
      </template>
      <div>
        <a-textarea v-model:value="trackMessageContent" placeholder="" :rows="4" disabled style="margin-bottom: 10px"/>
        <a-table :columns="trackColumns" :data-source="convertedDataSource(trackData)" :pagination="false" class="trackTable"></a-table>
      </div>
    </a-modal>
    <a-modal
        v-model:visible="trackSmsModalVisible"
        title="Track Message"
        :mask-closable="false"
        :centered="true"
        :footer="null"
        width="820px">
      <template #closeIcon>
        <close-icon/>
      </template>
      <div>
        <div class="trackItemsContainer">
          <TrackItem class="trackItem" item-title="Sid" :item-value="trackSmsItem.sid"/>
          <TrackItem class="trackItem" item-title="Status" :item-value="trackSmsItem.status"/>
          <TrackItem class="trackItem" item-title="From" :item-value="trackSmsItem.from"/>
          <TrackItem class="trackItem" item-title="To" :item-value="trackSmsItem.to"/>
          <TrackItem class="trackItem" item-title="Message Segments" :item-value="trackSmsItem.numSegments"/>
          <TrackItem class="trackItem" item-title="Cost" :item-value="trackSmsItem.cost"/>

        </div>
        <div class="trackItemTitle">
          Body
        </div>
        <a-textarea v-model:value="trackMessageContent" placeholder="" :rows="4" disabled
                    style="margin: 10px 0 30px 0"/>

        <div class="trackItemTitle">
          Delivery Steps
        </div>
        <a-table :columns="trackSmsColumns" :data-source="convertedDataSource(trackData)" :pagination="false" class="trackTable"></a-table>
      </div>
    </a-modal>
    <a-modal
        v-model:visible="sendMessageModalVisible"
        title="Send"
        :mask-closable="false"
        :centered="true"
        :footer="null"
        width="820px"
        @cancel="resetSendState"
        @ok="resetSendState">
      <template #closeIcon>
        <close-icon/>
      </template>
      <a-form layout="vertical" :model="sendState" @finish="handleSaveMessage">
        <a-form-item label="MessageType">
          <a-select placeholder="Please Select"
                    v-model:value="sendState.msgType"
                    :options="sendMessageTypeOptions">
          </a-select>
        </a-form-item>
        <a-form-item label="target" name="target" v-if="sendState.msgType !== 'IVI'" required
                     :rules="[{ required: true, message: 'target is required!' }]">
          <a-input v-model:value="sendState.target" placeholder="Please Input"/>
        </a-form-item>
        <a-form-item label="target" name="terminal" v-if="sendState.msgType === 'IVI'" required
                     :rules="[{ required: true, message: 'terminal is required!' }]">
          <a-select placeholder="Please Select"
                    v-model:value="sendState.terminal"
                    mode="multiple"
                    :options="terminalOptions">
          </a-select>
        </a-form-item>
        <a-form-item label="vin" v-if="sendState.msgType === 'IVI'">
          <a-input v-model:value="sendState.vin" placeholder="Please Input"/>
        </a-form-item>
        <a-form-item label="templateId" v-if="sendState.msgType !== 'FIREBASE'">
          <a-input v-model:value="sendState.templateId" placeholder="Please Input"/>
        </a-form-item>
        <a-form-item label="topic" v-if="sendState.msgType === 'MQ' || sendState.msgType ===''">
          <a-input v-model:value="sendState.topic" placeholder="Please Input"/>
        </a-form-item>
        <a-form-item label="alert type" v-if="sendState.msgType === 'FIREBASE' || sendState.msgType ===''">
          <a-input v-model:value="sendState.alertType" placeholder="Please Input"/>
        </a-form-item>
        <a-form-item label="subject" v-if="sendState.msgType !== 'IVI'">
          <a-input v-model:value="sendState.subject" placeholder="Please Input"/>
        </a-form-item>
        <a-form-item label="title" v-if="sendState.msgType === 'IVI'">
          <a-input v-model:value="sendState.title" placeholder="Please Input"/>
        </a-form-item>
        <a-form-item label="controlType" v-if="sendState.msgType === 'IVI'">
          <a-radio-group v-model:value="sendState.controlType">
            <a-radio-button value="NOTICE">NOTICE</a-radio-button>
            <a-radio-button value="CONTROL">CONTROL</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="schema" v-if="sendState.msgType === 'FIREBASE' || sendState.msgType ===''">
          <a-radio-group v-model:value="sendState.schema">
            <a-radio-button value="topic">topic</a-radio-button>
            <a-radio-button value="token">token</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="content">
          <a-input v-model:value="sendState.content" placeholder="Please Input"/>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit" ghost>
            Save
          </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>

</template>

<script setup>

import {onMounted, reactive, ref} from "vue";
import {
  getHistory,
  trackMessage,
  sendEmail,
  sendSms,
  sendFirebase,
  sendMqtt,
  sendIvi,
  queryMessage
} from "@/api/messageQuery";
import {SearchOutlined, SendOutlined} from '@ant-design/icons-vue';
import CloseIcon from "@/components/icon/CloseIcon.vue"
import ContentTitle from "@/components/ContentTitle.vue";
import {message} from "ant-design-vue";
import TrackItem from "@/components/MessageQuery/TrackItem.vue";
import {convertUTCToLocal} from "@/hooks/dateFormat";

const formState = reactive({
  layout: 'vertical',
  messageType: '',
  keyWords: '',
  clientName: '',
  success: '0',
  failure: '0'
});

const timeRangeValue = ref([]);
const messageTypeOptions = ref([
  {label: 'ALL', value: ''},
  {label: 'SMS', value: 'SMS'},
  {label: 'EMAIL', value: 'EMAIL'},
  {label: 'FIREBASE', value: 'FIREBASE'},
  {label: 'IVI', value: 'IVI'}
]);

const sendMessageTypeOptions = ref([
  {label: 'SMS', value: 'SMS'},
  {label: 'EMAIL', value: 'EMAIL'},
  {label: 'MQTT', value: 'MQ'},
  {label: 'IVI', value: 'IVI'},
  {label: 'FIREBASE', value: 'FIREBASE'}
]);


const clientNameList = ref([])
const loading = ref(false)
const trackData = ref([])
const trackModalVisible = ref(false)
const trackSmsModalVisible = ref(false)
const trackMessageContent = ref('')
const sendMessageModalVisible = ref(false)
const isTrackingDialog = ref(false)

const sendState = reactive({
  target: '',
  topic: '',
  content: '',
  msgType: '',
  subject: '',
  alertType: '',
  templateId: '',
  vin: '',
  title: '',
  controlType: 'NOTICE',
  schema: 'topic',
  terminal: []
});

const trackSmsItem = reactive({
  numSegments: 1,
  from: '',
  to: '',
  price: 0,
  priceUnit: 'USD',
  cost: '$0.0000',
  status: '',
  sid: '',
})

const showTotalFunction = (total) => `Total ${total}`;

const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  position: 'both',
  pageSizeOptions: ['10', '20', '30', '40', '50', '100'],
  total: 0,
  showTotal: showTotalFunction,
  showQuickJumper: true,
});

const columns = [{
  title: 'Client',
  key: 'clientId',
  dataIndex: 'clientId',
}, {
  title: 'MessageId',
  key: 'messageId',
  dataIndex: 'messageId',
}, {
  title: 'MessageType',
  key: 'messageType',
  dataIndex: 'messageType',
}, {
  title: 'Endpoint',
  key: 'endpoint',
  dataIndex: 'endpoint',
}, {
  title: 'Topic',
  key: 'topic',
  dataIndex: 'topic',
}, {
  title: 'Status',
  key: 'statusName',
  dataIndex: 'statusName',
}, {
  title: 'PushTime',
  key: 'pushTime',
  dataIndex: 'pushTime',
}, {
  title: 'Operation',
  key: 'operation',
  dataIndex: 'operation',
}];

const trackColumns = [{
  title: 'Status',
  key: 'status',
  dataIndex: 'status',
}, {
  title: 'Step',
  key: 'messageType',
  dataIndex: 'messageType',
}, {
  title: 'Remark',
  key: 'remark',
  dataIndex: 'remark',
}, {
  title: 'PushTime',
  key: 'pushTime',
  dataIndex: 'pushTime',
}];

const trackSmsColumns = [{
  title: 'Step',
  key: 'status',
  dataIndex: 'status',
}, {
  title: 'Time',
  key: 'pushTime',
  dataIndex: 'pushTime',
}];

const terminalOptions = [
  {value: '1', label: '1'},
  {value: '2', label: '2'},
  {value: '3', label: '3'},
  {value: '4', label: '4'},
  {value: '5', label: '5'},
  {value: '6', label: '6'},
  {value: '7', label: '7'},
  {value: '8', label: '8'},
  {value: '9', label: '9'}
];

const data = ref([]);

const convertedDataSource = dataSource => {
  return dataSource?.map(item => ({
    ...item,
    pushTime: convertUTCToLocal(item.pushTime),
  }));
};

onMounted(() => {
  handleSearch()
})

const handleSearch = () => {
  let startTime, endTime;
  if (timeRangeValue.value.length === 0) {
    let nowDate = new Date()
    startTime = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate()).getTime()
    endTime = nowDate.getTime()
  } else {
    startTime = new Date(timeRangeValue.value[0].year(), timeRangeValue.value[0].month(), timeRangeValue.value[0].date()).getTime()
    let endDate = new Date(timeRangeValue.value[1].year(), timeRangeValue.value[1].month(), timeRangeValue.value[1].date())
    endDate.setDate(endDate.getDate() + 1)
    endTime = endDate.getTime()
  }

  loading.value = true
  getHistory(startTime, endTime, pagination.value.current, pagination.value.pageSize, formState.messageType,
      formState.keyWords, 0, 0, 0, formState.clientName).then(res => {
    res.data.list.forEach(item => {
      if (item.status === 1) {
        item.statusName = 'Normal'
      }
      if (item.status === 2) {
        item.statusName = 'Delete'
      }
      if (item.status === 3) {
        item.statusName = 'Disabled'
      }
    })

    pagination.value.total = res.data.totalNum;
    pagination.value.showTotal = showTotalFunction;
    data.value = res.data.list;
  }).catch(err => {
    console.error('getHistory error: ', err)
  }).finally(() => {
    loading.value = false
  })

  queryMessage(startTime, endTime, sessionStorage.getItem('CloudMES-clientId') || '').then(res => {
    if (res.code === 200) {
      formState.failure = res.data.failureCount
      formState.success = res.data.successCount
      formState.total = res.data.total
    }
  })
}

const handleTrackMessage = item => {
  if(isTrackingDialog.value) return

  isTrackingDialog.value = true

  console.log('Track message: ' + item)
  trackData.value = []
  trackMessageContent.value = item.messageContent
  trackMessage(item.messageId).then(res => {
    if (res.code === 200) {
      console.log(res.data)
      if (item.messageType === 'SMS') {
        trackSmsModalVisible.value = true
        trackModalVisible.value = false
      } else {
        trackSmsModalVisible.value = false
        trackModalVisible.value = true
      }

      res.data.receiptStatus.forEach(tempItem => {
        let trackItem = {
          status: tempItem.status,
          messageType: item.messageType,
          remark: tempItem.remark,
          pushTime: tempItem.time
        }

        trackData.value.push(trackItem)
      })

      trackSmsItem.from = res.data.trackMessage.from.endpoint
      trackSmsItem.to = res.data.trackMessage.to
      trackSmsItem.price = Number(res.data.trackMessage.price)
      trackSmsItem.priceUnit = res.data.trackMessage.priceUnit
      trackSmsItem.numSegments = res.data.trackMessage.numSegments
      trackSmsItem.status = res.data.trackMessage.status
      trackSmsItem.sid = res.data.trackMessage.sid
      trackSmsItem.cost = trackSmsItem.priceUnit === "USD" ? "$" + Math.abs(trackSmsItem.price) : trackSmsItem.priceUnit + Math.abs(trackSmsItem.price)

    } else {
      console.warn('trackMessage failed: ' + res)
    }
  }).catch(err => {
    console.error('trackMessage error: ' + err)
  }).finally(()=>{
    isTrackingDialog.value = false
  })
}

const handleTableChange = (paginationObject) => {
  pagination.value = paginationObject;
  handleSearch();
}

const handleSend = () => {
  sendMessageModalVisible.value = true
}

const handleSendMessageResult = (res) => {
  if (res.code === 200 || res.code === 202) {
    message.success('Success')
    sendMessageModalVisible.value = false
    resetSendState()
  }
}

const resetSendState = () => {
  sendState.target = ''
  sendState.topic = ''
  sendState.content = ''
  sendState.msgType = ''
  sendState.subject = ''
  sendState.alertType = ''
  sendState.templateId = ''
  sendState.vin = ''
  sendState.title = ''
  sendState.controlType = 'NOTICE'
  sendState.terminal = []
  sendState.schema = 'topic'
}

const handleSaveMessage = (values) => {
  console.log('Success:', values);
  const params = {
    msgData: sendState,
    msgType: sendState.msgType
  }

  if (sendState.msgType === 'SMS') {
    sendSms(params).then(res => {
      handleSendMessageResult(res)
    }).finally(() => {
      loading.value = false
    })
  }

  if (sendState.msgType === 'EMAIL') {
    loading.value = true
    sendEmail(params).then(res => {
      handleSendMessageResult(res)
    }).finally(() => {
      loading.value = false
    })
  }

  if (sendState.msgType === 'FIREBASE') {
    loading.value = true
    let firebaseParams = {
      msgData: sendState,
      msgType: sendState.msgType,
      schema: sendState.schema
    }

    sendFirebase(firebaseParams).then(res => {
      handleSendMessageResult(res)
    }).finally(() => {
      loading.value = false
    })
  }

  if (sendState.msgType === 'MQ') {
    loading.value = true
    sendMqtt(params).then(res => {
      handleSendMessageResult(res)
    }).finally(() => {
      loading.value = false
    })
  }

  if (sendState.msgType === 'IVI') {
    loading.value = true
    const {terminal, vin, title, content, templateId, msgType, controlType} = sendState
    const iviParams = {
      terminal: terminal.length > 0 ? terminal.join(',') : '',
      vin,
      msgType,
      controlType,
      templateId,
      datas: {
        title,
        content
      }
    }

    sendIvi(iviParams).then(res => {
      handleSendMessageResult(res)
    }).finally(() => {
      loading.value = false
    })
  }
}

</script>

<style lang="scss" scoped>

.container {
  min-width: 800px;

  .formItemColumn {
    min-width: 200px;
  }

  .queryContainer {
    .columnGutter {
      margin: 0 16px;
    }

    .btnContainer {
      display: flex;
      justify-content: right;
      align-items: center;
      padding: 10px 0;

      .btnMargin {
        margin-left: 10px;
      }
    }
  }
}

.trackItemsContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .trackItem {
    width: 50%;
  }
}

</style>