import axios from "@/axios"

/**
 * get access menu
 * @function getAccessMenuAjax
 * @returns {promise} axios
 */
export function getTemplateListAjax(pageIndex, pageSize, clientId, messageType) {
    return axios({
        method: 'get',
        url: `api/notification/v1/template/list?pageIndex=${pageIndex}&pageSize=${pageSize}&clientId=${clientId}&messageType=${messageType}`
    })
}

/**
 * delete template
 * @function deleteTemplateAjax
 * @returns {promise} axios
 */
export function deleteTemplateAjax(templateId) {
    return axios({
        method: 'get',
        url: `api/notification/v1/template/deleted?templateId=${templateId}`
    })
}

/**
 * add template
 * @function addTemplateAjax
 * @returns {promise} axios
 */
export function addTemplateAjax(data) {
    return axios({
        method: 'post',
        url: 'api/notification/v1/template/create',
        data: data

    })
}

/**
 * edit template
 * @function editTemplateAjax
 * @returns {promise} axios
 */
export function editTemplateAjax(data) {
    return axios({
        method: 'post',
        url: 'api/notification/v1/template/edit',
        data: data

    })
}