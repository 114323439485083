import {createRouter, createWebHistory} from 'vue-router'
import MainLayout from '@/layout/MainLayout.vue'
import store from "@/store";
import {serverConfig} from "@/hooks/serverConfig"
import NoFoundView from "@/views/404View.vue"
import MessageQueryView from "@/views/messageQuery/MessageQueryView.vue"
import DashboardView from "@/views/dashboard/DashboardView.vue"
import SMSView from "@/views/template/SMSView.vue"
import EmailView from "@/views/template/EmailView.vue"
import IVIView from "@/views/template/IVIView.vue"
import FireBaseView from "@/views/template/FirebaseView.vue"
import DemoView from "@/views/DemoView.vue";

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'index',
    component: MainLayout,
    children: [{
        path: '/error',
        name: 'error',
        component: () => import('@/views/ErrorView.vue')  // which is lazy-loaded when the route is visited.
      }, {
        path: '/no-found',
        name: 'no-found',
        component: NoFoundView
      }, {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardView
      }, {
        path: '/sms',
        name: 'sms',
        component: SMSView
      }, {
        path: '/email',
        name: 'email',
        component: EmailView
      }, {
        path: '/ivi',
        name: 'ivi',
        component: IVIView
      }, {
        path: '/firebase',
        name: 'firebase',
        component: FireBaseView
      }, {
        path: '/message-query',
        name: 'message-query',
        component: MessageQueryView
      }, {
        path: '/demo',
        name: 'demo',
        component: DemoView
      }
    ]
  }
]

export const whiteList = ['/error', '/no-found']
export const moduleList = ['/error', '/no-found', '/dashboard', '/message-query', '/sms', '/email', '/ivi', '/firebase', '/demo']

const router = createRouter({
  history: createWebHistory(), // createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (!moduleList.includes(to.path.toLowerCase())) {
    return next('/no-found')
  }
  if (to.name) {
    if (['/sms', '/email', '/ivi', '/firebase'].includes(to.path.toLowerCase())) {
      console.log(to.path)
      store.commit('update', { openKeys: ["notificationEngine", 'subOne'], selectedKeys: [to.name] })
    } else {
      store.commit('update', { openKeys: ["notificationEngine"], selectedKeys: [to.name] })
    }
  }
  if (whiteList.includes(to.path.toLowerCase())){
    return next()
  }
  if (store.state.token && store.state.userInfo) {
    return next()
  }
  else {
    const token = to.query.token
    if (token) {
      store.dispatch('loginUser', { token: token }).then(()=>{
        return next(to.path)
      })
    } else {
      window.location.href = serverConfig.baseUrl + "/api/cccaccount/v1/account/login?clientId=" + serverConfig.clientId + "&redirectUri=" + serverConfig.self
    }
  }
})

export default router
