<template>
  <div class="headerArea">
    <div class="title"></div>
    <div class="userArea"><img alt="Username" src="../assets/account.svg" height="30" width="30"><span>{{userName}}</span></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import store from "@/store"
export default defineComponent({
  name: 'MainHeader',
  components: {
  },
  setup() {
    const userName = store.state.userInfo ? store.state.userInfo.nickname : ''
    return {
      userName
    }
  }
})
</script>

<style lang="scss">
@import "@/style/design.scss";
.headerArea {
  color: $main-color;
  .title {
    float: left;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
  }
  .userArea {
    float: right;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    img {
      margin-top: -2px;
    }
    span {
      margin-left: 10px;
    }
  }
}
</style>