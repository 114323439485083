<template>
  <ContentTitle msg="IVI"/>
  <div class="iviView">
    <div class="operations">
      <a-button type="primary" @click="handleAdd">+ Add</a-button>
      <div>
        <span class="marginLeft">ClientName</span>
        <a-select
            v-model:value="selectClientName"
            :options="clientNameOptions"
            placeholder="Select"
            class="marginLeft"
            style="width: 120px;"/>
        <a-button type="primary" class="marginLeft" @click="handleQuery">Query</a-button>
        <a-button type="primary" class="marginLeft" @click="handleQuery">Clear</a-button>
      </div>
    </div>
    <div class="tables">
      <a-table
          :data-source="convertedDataSource(dataSource)"
          :columns="columns"
          size="small"
          emptyText="No Data"
          :pagination="false"
          :loading="loading"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'status'">
            <span>
              {{ record.status === 1 ? 'Normal' : '' }}
            </span>
          </template>
          <template v-else-if="column.dataIndex === 'operation'">
            <a @click="handleEdit(record)">Edit</a>
            <a-popconfirm
                v-if="dataSource.length"
                title="Are you sure to delete the current data?"
                @confirm="handleDelete(record)">
              <a class="marginLeft">Delete</a>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
    </div>
    <div class="paginationArea">
      <div class="pageTotal">Showing {{ currentPage * pageSize > totalNumber ? totalNumber : currentPage * pageSize }}
        of {{ totalNumber }}
      </div>
      <a-pagination v-model:current="currentPage" v-model:pageSize="pageSize" :total="totalNumber"
                    :show-size-changer="false">
        <template #itemRender="{ type, originalElement }">
          <a v-if="type === 'prev'">
            <left-outlined/>
            Previous</a>
          <a v-else-if="type === 'next'">Next
            <right-outlined/>
          </a>
          <component :is="originalElement" v-else></component>
        </template>
      </a-pagination>
    </div>

    <a-modal
        v-model:visible="visible"
        title="Template Set"
        :mask-closable="false"
        :centered="false"
        :confirm-loading="confirmLoading"
        ok-text="Save"
        cancel-text="Cancel"
        :ok-button-props="{ size: 'large' }"
        :cancel-button-props="{ size: 'large' }"
        width="600px"
        @ok="handleOk"
        @cancel="handleCancel">
      <template #closeIcon>
        <close-icon/>
      </template>
      <a-form ref="formRef" layout="vertical" :model="iviFormState" :rules="rules">
        <a-form-item has-feedback label="Template Name" name="templateName">
          <a-input v-model:value="iviFormState.templateName" type="text" autocomplete="off"
                   placeholder="Template Name"/>
        </a-form-item>
        <a-form-item has-feedback label="Message Mode" name="mode">
          <a-radio-group v-model:value="iviFormState.mode">
            <a-radio-button value="Notice">Notice</a-radio-button>
            <a-radio-button value="Control">Control</a-radio-button>
          </a-radio-group>
        </a-form-item>

        <a-form-item has-feedback label="Terminal" name="terminal">
          <a-checkable-tag
              v-for="(data, index) in terminalData"
              :key="data"
              v-model:checked="selectTerminal[index]"
              @change="checked => handleTerminalChange(data, checked)"
          >
            {{ data }}
          </a-checkable-tag>
        </a-form-item>

        <a-form-item has-feedback label="Tags" name="tags">
          <a-checkable-tag
              v-for="(tag, index) in tagsData"
              :key="tag"
              v-model:checked="selectTags[index]"
              @change="checked => handleTagsChange(tag, checked)"
          >
            {{ tag }}
          </a-checkable-tag>
        </a-form-item>
        <a-form-item has-feedback label="Content" name="content">
          <a-textarea v-model:value="iviFormState.content" disabled :rows="4"/>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref, watch} from "vue";
import {addTemplateAjax, deleteTemplateAjax, editTemplateAjax, getTemplateListAjax} from "@/api/template";
import CloseIcon from "@/components/icon/CloseIcon.vue";
import {message} from "ant-design-vue";
import ContentTitle from "@/components/ContentTitle.vue";
import {LeftOutlined, RightOutlined} from "@ant-design/icons-vue"
import {convertUTCToLocal} from "@/hooks/dateFormat";

const selectClientName = ref('')
const loading = ref(false);
const confirmLoading = ref(false);
const clientNameOptions = ref([])
const dataSource = ref([])
const columns = ref([
  {
    title: 'Name',
    dataIndex: 'templateName',
    key: 'templateName',
  },
  {
    title: 'TemplateId',
    dataIndex: 'templateId',
    key: 'templateId',
  },
  {
    title: 'Content',
    dataIndex: 'content',
    key: 'content',
  },
  {
    title: 'MessageMode',
    dataIndex: 'messageMode',
    key: 'messageMode',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'AddDate',
    dataIndex: 'addDate',
    key: 'addDate',
  },
  {
    title: 'ModifyDate',
    dataIndex: 'modifyDate',
    key: 'modifyDate',
  },
  {
    title: 'Operation',
    dataIndex: 'operation',
    key: 'operation',
  },
])
const currentPage = ref(1)
const totalNumber = ref(0)
const pageSize = ref(10)
const visible = ref(false);
const tagsData = reactive([' {code}', ' {url}', ' {event}']);
const selectTags = ref([false, false, false]);
const terminalData = reactive(['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']);
const selectTerminal = ref([false, false, false, false, false, false, false, false, false]);
const contentHolder = 'Your verification code is ';

const formRef = ref();
const iviFormState = reactive({
  templateName: '',
  mode: '',
  tags: [],
  terminal: [],
  content: contentHolder,
  templateId: ''
})

let validateName = async (_rule, value) => {
  if (value === '') {
    return Promise.reject('Please input the template name.')
  } else {
    return Promise.resolve()
  }
}

let validateMode = async (_rule, value) => {
  if (value === '') {
    return Promise.reject('Please select the message mode.')
  } else {
    return Promise.resolve()
  }
}

let validateTags = async (_rule, value) => {
  if (value.length === 0) {
    return Promise.reject('Please select the tags.')
  } else {
    return Promise.resolve()
  }
}

let validateTerminal = async (_rule, value) => {
  if (value.length === 0) {
    return Promise.reject('Please select the terminal.')
  } else {
    return Promise.resolve()
  }
}

const rules = {
  templateName: [{
    required: true,
    validator: validateName,
    trigger: 'change',
  }],
  mode: [{
    required: true,
    validator: validateMode,
    trigger: 'change',
  }],
  tags: [{
    required: true,
    validator: validateTags,
    trigger: 'change',
  }],
  terminal: [{
    required: true,
    validator: validateTerminal,
    trigger: 'change',
  }],
  content: [{
    required: true,
    trigger: 'change',
  }],
}

const getData = () => {
  loading.value = true;
  getTemplateListAjax(currentPage.value, pageSize.value, '', 'IVI').then(res => {
    console.log(res)
    dataSource.value = res.data.list;
    loading.value = false;
    totalNumber.value = res.data.totalNum;
  }).catch(err => {
    console.log(err);
    loading.value = false;
  })
}

const handleOk = () => {
  formRef.value.validate().then(() => {
    confirmLoading.value = true
    //在此处使用setTimeout做一定的延迟来模拟请求后端api
    const data = {
      clientId: '',
      templateId: iviFormState.templateId,
      content: iviFormState.content,
      messageMode: iviFormState.mode,
      messageType: 'IVI',
      tags: iviFormState.tags.join(', '),
      terminal: iviFormState.terminal.join(','),
      templateName: iviFormState.templateName
    };
    console.log(data);

    if (iviFormState.templateId) {
      editTemplateAjax(data).then(res => {
        console.log(res);
        visible.value = false
        confirmLoading.value = false
        message.success('update template ivi successfully.')
        selectTags.value = [false, false, false];
        selectTerminal.value = [false, false, false, false, false, false, false, false, false]
        getData();
      }).catch(err => {
        visible.value = false
        confirmLoading.value = false
        console.log(err);
      })
    } else {
      addTemplateAjax(data).then(res => {
        console.log(res);
        visible.value = false
        confirmLoading.value = false
        message.success('add template ivi successfully.')
        selectTags.value = [false, false, false];
        selectTerminal.value = [false, false, false, false, false, false, false, false, false]
        getData();
      }).catch(err => {
        visible.value = false
        confirmLoading.value = false
        console.log(err);
      })
    }
  }).catch(error => {
    console.log('error', error)
  })
}
const handleTagsChange = (tag, checked) => {
  console.log(tag, checked)
  if (checked) {
    iviFormState.tags.push(tag);
  } else {
    const index = iviFormState.tags.indexOf(tag);
    console.log(iviFormState.tags)
    if (index !== -1) {
      iviFormState.tags.splice(index, 1);
    }
  }
  if (iviFormState.tags.length > 0) {
    iviFormState.content = contentHolder + iviFormState.tags.join(" , ");
  } else {
    iviFormState.content = contentHolder;
  }
  formRef.value.validate();
};

const handleTerminalChange = (data, checked) => {
  console.log(data, checked)

  if (checked) {
    iviFormState.terminal.push(data);
  } else {
    const index = iviFormState.terminal.indexOf(data);
    console.log(iviFormState.terminal)
    if (index !== -1) {
      iviFormState.terminal.splice(index, 1);
    }
  }
  formRef.value.validate();
};

const handleCancel = () => {
  formRef.value.resetFields();
  selectTags.value = [false, false, false];
}

const handleQuery = () => {
  currentPage.value = 1;
  getData();
}

const convertedDataSource = dataSource => {
  return dataSource?.map(item => ({
    ...item,
    modifyDate: convertUTCToLocal(item.modifyDate),
    addDate: convertUTCToLocal(item.addDate),
  }));
};

const handleDelete = (item) => {
  console.log(item);
  if (item.templateId) {
    deleteTemplateAjax(item.templateId).then(res => {
      console.log(res);
      getData();
    }).catch(err => {
      console.log(err);
    })
  }
}

const handleEdit = (item) => {
  console.log(item.terminal );
  iviFormState.templateName = item.templateName;
  iviFormState.mode = item.messageMode;
  iviFormState.tags = (item.tags === null) ? null : item.tags.split(',');
  iviFormState.templateId = item.templateId;
  iviFormState.terminal = (item.terminal === null) ? null: item.terminal.split(',');

  if(iviFormState.tags != null) {
    const isCode = iviFormState.tags.toString().indexOf("{code}") !== -1;
    const isUrl = iviFormState.tags.toString().indexOf("{url}") !== -1;
    const isEvent = iviFormState.tags.toString().indexOf("{event}") !== -1;
    selectTags.value = [isCode, isUrl, isEvent];

    if (iviFormState.tags.length > 0) {
      iviFormState.content = contentHolder + iviFormState.tags.join(" , ");
    } else {
      iviFormState.content = contentHolder;
    }
  }

  if(iviFormState.terminal != null) {
    const is1 = iviFormState.terminal.toString().indexOf("One") !== -1;
    const is2 = iviFormState.terminal.toString().indexOf("Two") !== -1;
    const is3 = iviFormState.terminal.toString().indexOf("Three") !== -1;
    const is4 = iviFormState.terminal.toString().indexOf("Four") !== -1;
    const is5 = iviFormState.terminal.toString().indexOf("Five") !== -1;
    const is6 = iviFormState.terminal.toString().indexOf("Six") !== -1;
    const is7 = iviFormState.terminal.toString().indexOf("Seven") !== -1;
    const is8 = iviFormState.terminal.toString().indexOf("Eight") !== -1;
    const is9 = iviFormState.terminal.toString().indexOf("Nine") !== -1;
    selectTerminal.value = [is1, is2, is3, is4, is5, is6, is7, is8, is9];
  }

  visible.value = true;


  console.log(item)
}

const handleAdd = () => {
  iviFormState.templateName = '';
  iviFormState.mode = '';
  iviFormState.tags = [];
  iviFormState.templateId = null;
  iviFormState.terminal = [];
  selectTags.value = [false, false, false];
  selectTerminal.value = [false, false, false, false, false, false, false, false, false]
  visible.value = true;
  console.log(visible.value)
}

watch(currentPage, () => {
  getData();
})

onMounted(() => {
  getData();
})
</script>


<style scoped lang="scss">
.iviView {

}
</style>