import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import './style/index.scss'
import {getTranslationMap} from '@/api/translation'

let data = { langCode: "en_US", appPrefixes: ["HA"] }
getTranslationMap(data).then(res => {
    console.log(res)
    store.commit('update', { languageResources: res})
    createApp(App).use(router).use(store).use(Antd).mount('#app')
}).catch(error => {
    console.log('getTranslationMap()->', error)
})

