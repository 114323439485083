<template>
  <a-menu
      mode="inline"
      class="leftMenu"
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      @openChange="handleOpenChange"
      @select="handleSelect"
  >
    <a-sub-menu key="notificationEngine">
      <template #icon>
        <HomeOutlined/>
      </template>
      <template #title>Notification Engine</template>
      <a-menu-item key="dashboard">DashBoard</a-menu-item>
      <a-sub-menu key="subOne">
        <template #title>Template</template>
        <a-menu-item key="sms">SMS</a-menu-item>
        <a-menu-item key="email">Email</a-menu-item>
        <a-menu-item key="ivi">IVI</a-menu-item>
        <a-menu-item key="firebase">FireBase</a-menu-item>
      </a-sub-menu>
      <a-menu-item key="message-query">Message Query</a-menu-item>
    </a-sub-menu>
  </a-menu>
  <div class="logoutFooter">
    <a @click="handleSignOut">
      <img alt="Logout" src="../assets/logout.svg" height="28" width="28">
      <span>Sign out</span>
    </a>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs, watch} from "vue"
import { HomeOutlined } from "@ant-design/icons-vue"
import store from "@/store"
import router from "@/router"

export default defineComponent({
  name: 'LeftSide',
  components: {
    HomeOutlined
  },
  setup() {
    const menuState = reactive({
      openKeys: store.state.openKeys === null? ['notificationEngine'] : store.state.openKeys,
      selectedKeys: store.state.selectedKeys === null? ['dashboard'] : store.state.selectedKeys
    })
    const handleOpenChange = (openKeys) => {
      store.commit('update', { openKeys: openKeys })
    }
    const handleSelect = (item) => {
      store.commit('update', { selectedKeys: item.selectedKeys })
      router.push({
        path: '/' + item.selectedKeys[0],
        name: item.selectedKeys[0]
      })
    }
    const handleSignOut = () => {
      store.dispatch('signOut')
    }

    watch(() => store.state.selectedKeys, () => {
      menuState.openKeys = store.state.openKeys === null? ['notificationEngine'] : store.state.openKeys;
      menuState.selectedKeys = store.state.selectedKeys === null? ['dashboard'] : store.state.selectedKeys;
    })

    onMounted(() => {

    })

    return {
      ...toRefs(menuState),
      handleOpenChange,
      handleSelect,
      handleSignOut
    }
  }
})
</script>

<style lang="scss">
@import "@/style/design.scss";
.ant-menu {
  background-color: transparent !important;
  color: $main-color !important;
}
.ant-menu-inline {
  border-right: none !important;
}
.ant-menu-submenu-title,.ant-menu-item {
  padding-left: 0 !important;
}
.ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
  color: $main-color !important;
}
.ant-menu-submenu-selected .ant-menu-submenu-expand-icon, .ant-menu-submenu-selected .ant-menu-submenu-arrow {
  color: $main-color-active !important;
}
.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover {
  color: $main-color-hover !important;
}
.ant-menu-item-selected,.ant-menu-submenu-selected {
  color: $main-color-active !important;
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: $main-color-hover !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(253, 252, 253, 0.4) !important;
  border-radius: 3px 0 0 3px;
}
.ant-menu-sub.ant-menu-inline {
  background: transparent !important;
  margin-left: 24px;
}
.ant-menu-inline {
  width: auto !important;
}
.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
  width: calc(100%) !important;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 10px !important;
}
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  border-right: none !important;
}

.logoutFooter {
  height: 50px;
  width: 260px;
  position: fixed;
  bottom: 0;
  a:hover,span:hover {
    color: $main-color-hover;
  }
  img {
    margin-top: -8px;
  }
  span {
    margin-left: 10px;
    color: $main-color;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
  }
}
</style>
