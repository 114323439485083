<template>
  <div class="box">
      <img class="img" :src="imageUrl" />
      <div class="font">
        <p> {{ props.message }}</p>
        <p>
          {{ props.cValue }}
        </p>
      </div>
      <img class="circle" :src="dotUrl"/>
  </div>
</template>

<script setup>
import {defineProps} from "vue"

const props = defineProps({
  message: String,
  cValue: String,
  imageUrl: String,
  dotUrl: String
})
</script>

<style lang="scss" scoped>

.box {
  background: #EDEDED;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;

  .img {
    width: 68px;
    height: 68px;
    margin-left: 10px;
  }

  .font {
    width: 220px;
    height: 68px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 35px;

    p:nth-child(1) {
      color: #4a5a7f;
      font-size: 14px;
    }

    p:nth-child(2) {
      font-size: 26px;
      font-weight: 600;
      color: #212b42;
    }
  }

  .circle {
    margin-top: -35px;
    margin-left: 75px;
  }
}


</style>