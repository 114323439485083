import {createStore} from 'vuex'
import _ from 'lodash'
import {removeCookieToken, removeAllCookies} from "@/store/cookieHelper"
import {updateRequestHeaders} from "@/axios"
import {myLocalStorage, mySessionStorage} from "@/store/storageHelper"
import {serverConfig} from "@/hooks/serverConfig"
import {getUserInfoAjax} from "@/api/user"

const memoizeIsLocalStorage = _.memoize(function(key) {
  return _.keys(myLocalStorage).includes(key)
})

const memoizeIsSessionStorage = _.memoize(function(key) {
  return _.keys(mySessionStorage).includes(key)
})

export default createStore({
  state: {
    token: myLocalStorage.token,
    userInfo: myLocalStorage.userInfo,
    selectedKeys: mySessionStorage.selectedKeys,
    openKeys: mySessionStorage.openKeys,
    languageResources: mySessionStorage.languageResources
  },
  getters: {},
  mutations: {
    update(state, obj) {
      _.map(obj, (value, key) => {
        state[key] = value
        try {
          memoizeIsSessionStorage(key) ? (mySessionStorage[key] = value) : (memoizeIsLocalStorage(key) ? (myLocalStorage[key] = value) : '')
        } catch (err) {
          console.error(err);
        }
      })
    },
    setToken(state, token) {
      state.token = token
      updateRequestHeaders({
        Authorization: 'Bearer ' + token
      })
    },
    removeToken(state) {
      state.token = ''
      removeCookieToken()
    },
    removeAllCookies(state) {
      state.token = ''
      removeAllCookies()
    }
  },
  actions: {
    loginUser(context, params) {
      context.commit('removeAllCookies')
      context.commit('setToken', params.token)
      context.commit('update', {
        token: params.token,
        openKeys: ['notificationEngine'],
        selectedKeys: ['dashboard']
      })
      return Promise.all([getUserInfoAjax()]).then(res=>{
        if (res[0].data) {
          context.commit('update', {
            userInfo: res[0].data
          })
          return true
        } else {
          return false
        }
      })
    },
    signOut(context) {
      const token = myLocalStorage.token
      myLocalStorage.removeAll()
      mySessionStorage.removeAll()
      context.commit('removeAllCookies')
      if (token) {
        window.location.href = serverConfig.baseUrl + "/api/v1/sign-sso/logout?token=" + token + "&targetUrl=" + serverConfig.self
      } else {
        window.location.href = serverConfig.self
      }
    }
  },
  modules: {}
})