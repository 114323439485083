<template>
  <ContentTitle msg="Demo"/>
  <a-button type="primary" size="large" @click="handleShowModel">Add</a-button>&nbsp;&nbsp;
  <a-button type="primary" size="large">OK</a-button>&nbsp;&nbsp;
  <a-button type="default" size="large">Cancel</a-button>&nbsp;&nbsp;
  <a-button type="primary" @click="handleConfirm">Modal Confirm</a-button>&nbsp;&nbsp;
  <a-button type="primary" @click="handleAlert">Modal Alert</a-button>&nbsp;&nbsp;
  <a-button type="default">Cancel</a-button>
  <div style="margin-top: 10px;">
    <a-button ghost size="large">Add</a-button>&nbsp;&nbsp;
    <a-button ghost size="large">OK</a-button>&nbsp;&nbsp;
    <a-button ghost size="large">Cancel</a-button>&nbsp;&nbsp;
    <a-button ghost>Add</a-button>&nbsp;&nbsp;
    <a-button ghost>OK</a-button>&nbsp;&nbsp;
    <a-button ghost>Cancel</a-button>
  </div>
  <div style="margin-top: 10px;">
    <a-button ghost>
      <template #icon><SearchOutlined/></template>
      Search
    </a-button>&nbsp;&nbsp;
    <a-button ghost>
      <template #icon>
        <sort-by-icon/>
      </template>
      Sort by
    </a-button>&nbsp;&nbsp;
    <a-button ghost>
      <template #icon>
        <filter-icon/>
      </template>
      Filter</a-button>
  </div>
  <div style="margin-top: 10px;">
    <div class="paginationArea">
      <div class="pageTotal">Showing {{ currentPage * pageSize > totalNumber ? totalNumber : currentPage * pageSize }} of {{ totalNumber }}</div>
      <a-pagination size="small" v-model:current="currentPage" v-model:pageSize="pageSize" :total="totalNumber" :default-page-size="10" :show-size-changer="false"/>
    </div>
    <div class="paginationArea">
      <div class="pageTotal">Showing {{ currentPage * pageSize > totalNumber ? totalNumber : currentPage * pageSize }} of {{ totalNumber }}</div>
      <a-pagination v-model:current="currentPage" v-model:pageSize="pageSize" :total="totalNumber" :show-size-changer="false">
        <template #itemRender="{ type, originalElement }">
          <a v-if="type === 'prev'"> <left-outlined/> Previous</a>
          <a v-else-if="type === 'next'">Next <right-outlined /></a>
          <component :is="originalElement" v-else></component>
        </template>
      </a-pagination>
    </div>
  </div>
  <div style="margin-top: 10px;">
    <a-range-picker v-model:value="value1" />
  </div>
  <div style="margin-top: 10px;">
    <a-range-picker v-model:value="value2" show-time />
  </div>
  <a-modal
      v-model:visible="visible"
      title="Your Title"
      :mask-closable="false"
      :centered="false"
      :confirm-loading="confirmLoading"
      ok-text="Add"
      cancel-text="Cancel"
      :ok-button-props="{ size: 'large' }"
      :cancel-button-props="{ size: 'large' }"
      width="465px"
      @ok="handleOk"
      @cancel="handleCancel">
    <template #closeIcon>
      <close-icon/>
    </template>
    <a-form ref="formRef" layout="vertical" :model="formState" :rules="rules" @validate="handleValidate">
      <a-form-item has-feedback label="Password" name="pass">
        <a-input v-model:value="formState.pass" type="password" autocomplete="off" placeholder="Password"/>
      </a-form-item>
      <a-form-item has-feedback label="Confirm" name="checkPass">
        <a-input v-model:value="formState.checkPass" type="password" autocomplete="off" placeholder="Confirm Password"/>
      </a-form-item>
      <a-form-item has-feedback label="Age" name="age">
        <a-input-number v-model:value="formState.age" placeholder="Age"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {defineComponent, reactive, ref, createVNode} from "vue"
import ContentTitle from "@/components/ContentTitle.vue"
import { SearchOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons-vue"
import { message, Modal } from 'ant-design-vue'
import SortByIcon from "@/components/icon/SortByIcon.vue"
import FilterIcon from "@/components/icon/FilterIcon.vue"
import CloseIcon from "@/components/icon/CloseIcon.vue"
import WarningIcon from "@/components/icon/WarningIcon.vue"
import SuccessIcon from "@/components/icon/SuccessIcon.vue"

export default defineComponent ({
  name: 'DemoView',
  components: {
    CloseIcon,
    FilterIcon,
    SortByIcon,
    WarningIcon,
    SuccessIcon,
    ContentTitle,
    SearchOutlined,
    LeftOutlined,
    RightOutlined
  },
  setup() {
    const value1 = ref()
    const value2 = ref()
    const currentPage = ref(1)
    const totalNumber = ref(60)
    const pageSize = ref(10)

    const visible = ref(false)
    const confirmLoading = ref(false)

    const formRef = ref();
    const formState = reactive({
      pass: '',
      checkPass: '',
      age: undefined,
    })

    let validatePass = async (_rule, value) => {
      if (value === '') {
        return Promise.reject('Please input the password')
      } else {
        if (formState.checkPass !== '') {
          formRef.value.validateFields('checkPass')
        }
        return Promise.resolve()
      }
    }

    let validatePass2 = async (_rule, value) => {
      if (value === '') {
        return Promise.reject('Please input the password again')
      } else if (value !== formState.pass) {
        return Promise.reject("Two inputs don't match!")
      } else {
        return Promise.resolve()
      }
    }

    let checkAge = async (_rule, value) => {
      if (!value) {
        return Promise.reject('Please input the age')
      }
      if (!Number.isInteger(value)) {
        return Promise.reject('Please input digits')
      } else {
        if (value <= 18) {
          return Promise.reject('Age must be greater than 18')
        } else {
          return Promise.resolve()
        }
      }
    }

    const rules = {
      pass: [{
        required: true,
        validator: validatePass,
        trigger: 'change',
      }],
      checkPass: [{
        validator: validatePass2,
        trigger: 'change',
      }],
      age: [{
        validator: checkAge,
        trigger: 'change',
      }],
    }

    const handleValidate = (...args) => {
      console.log(args)
    }

    const handleShowModel = () => {
      visible.value = true
      formRef.value?.resetFields()
    }

    const handleOk = () => {
      formRef.value.validate().then(() => {
        confirmLoading.value = true
        //在此处使用setTimeout做一定的延迟来模拟请求后端api
        setTimeout(()=> {
          visible.value = false
          confirmLoading.value = false
          message.success('This is a success message')
        }, 1500)
      }).catch(error => {
        console.log('error', error)
      })
    }

    const handleCancel = () => {
      formRef.value.resetFields();
      message.info('This is info message.')
      message.error("This is an error message.")
      message.warning("This is a warning message.")
      message.success("This is a success message.")
    }

    const handleConfirm = () => {
      Modal.confirm({
        title: 'Are you sure delete this item?',
        icon: createVNode(WarningIcon),
        content: '',
        centered: true,
        closable: true,
        autoFocusButton: null,
        width: '360px',
        okText: 'Ok',
        okType: 'primary',
        cancelText: 'Cancel',
        onOk() {
          console.log('OK');
        },
        onCancel() {
          console.log('Cancel');
        }})
    }

    const handleAlert = () => {
      Modal.info({
        title: 'New Vehicle added successfully.',
        icon: createVNode(SuccessIcon),
        content: '',
        centered: true,
        closable: true,
        autoFocusButton: null,
        width: '360px',
        okText: 'Ok',
        okType: 'primary',
        onOk() {
          console.log('OK');
        },
        onCancel() {
          console.log('Cancel');
        }})
    }

    return {
      value1,
      value2,
      currentPage,
      totalNumber,
      pageSize,
      visible,
      confirmLoading,
      formRef,
      formState,
      rules,
      handleValidate,
      handleShowModel,
      handleOk,
      handleCancel,
      handleConfirm,
      handleAlert
    }
  }
})
</script>