
export const myLocalStorage = {
    token: '',
    userInfo: '',
    userName: '',
    remove: (key) => localStorage.removeItem(key),
    removeAll: () => Object.keys(myLocalStorage).map(key => localStorage.removeItem(key))
}

export const mySessionStorage = {
    selectedKeys: [],
    openKeys: [],
    languageResources: null,
    remove: (key) => sessionStorage.removeItem(key),
    removeAll: () => Object.keys(mySessionStorage).map(key => sessionStorage.removeItem(key))
}

function initStorage(storage, type) {
    Object.preventExtensions(storage)
    Object.getOwnPropertyNames(storage).map(key => {
        if (key === 'remove' || key === 'removeAll') {
            Object.defineProperty(storage, key, {
                enumerable: false,
                writable: false
            })
            return
        }
        Object.defineProperty(storage, key, {
            configurable: true,
            enumerable: true,
            get: () => {
                const value = type === 'local' ? localStorage.getItem(key) : sessionStorage.getItem(key)
                try {
                    return JSON.parse(value)
                } catch (error) {
                    return value
                }
            },
            set: newVal => {
                newVal = typeof newVal !== 'string' ? JSON.stringify(newVal) : newVal
                type === 'local' ? localStorage.setItem(key, newVal) : sessionStorage.setItem(key, newVal)
            }
        })
    })
}

initStorage(myLocalStorage, 'local')
initStorage(mySessionStorage, 'session')

export default myLocalStorage
