<template>
  <ContentTitle msg="Dashboard"/>
  <div class="root">
    <div class="search">
      <a-space direction="vertical" :size="12">
        <a-range-picker v-model:value="dateRange"/>
      </a-space>

      <a-button ghost  type="primary" class="searchButton" @click="doSearch">
        <template #icon><SearchOutlined/></template>
        Search
      </a-button>
    </div>
    <div class="cardRoot">
      <dash-card message="Number Of Messages Sent(H)" class="card"
                 :c-value="sentTotal"
                 :image-url="getImage(1)"
                 :dot-url="getImage(4)"></dash-card>
      <dash-card message="Delivery Success Rate(H)" class="card"
                 :c-value="successRate"
                 :image-url="getImage(2)"
                 :dot-url="getImage(4)"></dash-card>
      <dash-card message="Number Of Failures(H)" class="card"
                 :c-value="failureCount"
                 :image-url="getImage(3)"
                 :dot-url="getImage(4)"></dash-card>
    </div>
    <div ref="chartDateCount" style="width: 100%; height: 400px;">
    </div>

    <div ref="chartRealTime" style="width: 100%; height: 400px;">
    </div>
  </div>

</template>

<script>
import {defineComponent, onMounted, ref} from "vue"
import ContentTitle from "@/components/ContentTitle.vue"
import DashCard from "@/components/dashboard/dashCard.vue";
import {SearchOutlined} from '@ant-design/icons-vue';
import * as echarts from 'echarts';
import {queryMessage,queryRealTimeData} from '@/api/dashboard/index'

import card1 from "@/assets/dashboard/card1.png";
import card2 from "@/assets/dashboard/card2.png";
import card3 from "@/assets/dashboard/card3.png";
import card4 from "@/assets/dashboard/card4.png";

export default defineComponent({
  name: 'DashboardView',
  components: {
    DashCard,
    ContentTitle,
    SearchOutlined
  },
  setup() {

    const chartDateCount = ref(null);
    const chartRealTime = ref(null);
    const dateRange = ref([]);
    const failureCount = ref(null);
    const sentTotal = ref(null);
    const successRate = ref(null);
    const dateCountList = ref([]);
    const chartData = ref([]);

    onMounted(() => {
      doSearch();
    });

    const initCharDateCount = () => {
      const optionDateCount = {
        title: {
          text: 'Date count'
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: dateCountList.value.map(item => item.date)
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: dateCountList.value.map(item => item.count),
          type: 'line',
          smooth: true
        }]
      };
      const chartDateCountInstance = echarts.init(chartDateCount.value);
      chartDateCountInstance.setOption(optionDateCount);
    }

    const initCharRealTime = () => {
      const optionRealTime = {
        title: {
          text: 'Real-time Today Data'
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: [
            '0:00',
            '1:00',
            '2:00',
            '3:00',
            '4:00',
            '5:00',
            '6:00',
            '7:00',
            '8:00',
            '9:00',
            '10:00',
            '11:00',
            '12:00',
            '13:00',
            '14:00',
            '15:00',
            '16:00',
            '17:00',
            '18:00',
            '19:00',
            '20:00',
            '21:00',
            '22:00',
            '23:00'
          ],
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: chartData.value,
          type: 'line',
          smooth: true
        }]
      };
      const chartRealTimeInstance = echarts.init(chartRealTime.value);
      chartRealTimeInstance.setOption(optionRealTime);
    }

    const doSearch = () => {
      if (dateRange.value) {
        const values = dateRange.value;
        if (values) {
          const params = {
            startTime: values[0] && values[1] ? Date.parse(new Date(values[0]).toString()) : getUTCTime(0, 0, 0),
            endTime: values[0] && values[1] ? Date.parse(new Date(values[1]).toString()) + 57599000 : getUTCTime(23, 59, 59)
          }

          queryMessage(params).then(res => {
            failureCount.value = res.data.failureCount;
            sentTotal.value = res.data.total;
            successRate.value = res.data.successRate + '%';
            dateCountList.value = res.data.dateCountList;
            initCharDateCount();
          })

          queryRealTimeData().then(res => {
            if (res.code === 200) {
              const arr = []
              for (let i = 0; i <= 23; i++) {
                arr.push(res.data[i])
              }
              chartData.value = arr
              initCharRealTime();
            } else {
              //this.$maxMsg.error({message: res.msg})
            }
          })

        }
      }

      function getUTCTime(hours, minutes, seconds) {
        const date1 = new Date()
        hours = hours != null ? hours : date1.getHours()
        minutes = minutes != null ? minutes : date1.getMinutes()
        seconds = seconds != null ? seconds : date1.getSeconds()
        const date2 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate(), hours, minutes, seconds)
        return date2
      }
    }

    const getImage = (index) => {
      switch (index) {
        case 1:
          return card1;
        case 2 :
          return card2;
        case 3:
          return card3;
        default:
          return card4;
      }
    }
    return {
      getImage,
      chartDateCount,
      chartRealTime,
      doSearch,
      dateRange,
      failureCount,
      sentTotal,
      successRate
    }
  }
})
</script>

<style lang="scss" scoped>
.root {
  .search {
    display: flex;
    align-items: center;

    .searchButton {
      margin-left: 10px;
    }
  }

  .cardRoot {
    width: 100%;
    height: 140px;
    padding: 15px 0;
    margin-top: 15px;
    display: flex;

    .card {
      margin-left: 8px;
    }
  }
}

</style>
