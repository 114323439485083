import axios from "@/axios"

/**
 * query Message
 * @function queryMessage
 * @returns {promise} axios
 */
export function queryMessage(startTime, endTime, clientId) {
    return axios({
        method: 'get',
        url: 'api/notification/v1/dashboard/queryMessage?startTime=' + startTime + '&endTime=' + endTime + '&clientId=' + clientId
    })
}

/**
 * track Message
 * @function trackMessage
 * @returns {promise} axios
 */
export function trackMessage(messageId) {
    return axios({
        method: 'get',
        url: 'api/notification/v1/push/track/' + messageId
    })
}

/**
 * get history message
 * @function getHistory
 * @returns {promise} axios
 */
export function getHistory(startTime, endTime, pageIndex, pageSize, messageType, topic, total, success, error, clientId) {
    return axios({
        method: 'get',
        url: `api/notification/v1/track/history?startTime=${startTime}&endTime=${endTime}&pageIndex=${pageIndex}&pageSize=${pageSize}&messageType=${messageType}&topic=${topic}&total=${total}&success=${success}&error=${error}&clientId=${clientId}`
    })
}


/**
 * send Email
 * @function sendEmail
 * @returns {promise} axios
 */
export function sendEmail(data) {
    return axios({
        url: 'api/notification/v1/push/email',
        method: 'post',
        data
    })
}

/**
 * send Firebase
 * @function sendFirebase
 * @returns {promise} axios
 */
export const sendFirebase = data => {
    return axios({
        url: 'api/notification/v1/push/firebase',
        method: 'post',
        data
    })
}

/**
 * send Mqtt
 * @function sendMqtt
 * @returns {promise} axios
 */
export const sendMqtt = data => {
    return axios({
        url: 'api/notification/v1/push/mq',
        method: 'post',
        data
    })
}

/**
 * send Sms
 * @function sendSms
 * @returns {promise} axios
 */
export const sendSms = data => {
    return axios({
        url: 'api/notification/v1/push/sms',
        method: 'post',
        data
    })
}

/**
 * send Ivi
 * @function sendIvi
 * @returns {promise} axios
 */
export const sendIvi = data => {
    return axios({
        url: 'api/notification/v1/push/ivi',
        method: 'post',
        data
    })
}