import axios from "@/axios"


/**
 * 获取信息
 * @param params
 * @returns {*}
 */
export const queryMessage = params => {
    return axios({
        url: '/api/notification/v1/dashboard/queryMessage',
        method: 'get',
        params
    })
}

// 获取当天信息
export const queryRealTimeData = params => {
    return axios({
        url: '/api/notification/v1/dashboard/realTimeData',
        method: 'get',
        params
    })
}