<template>
  <div class="contentTitle">
    <span>{{ msg }}</span>
  </div>
</template>

<script>
export default {
  name: 'ContentTitle',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/style/design.scss";
.contentTitle {
  margin-bottom: 20px;
  span {
    color: $main-color-active;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 100%;
 }
}
</style>
