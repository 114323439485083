import jsCookie from "js-cookie"
import jsCookieExtension from 'js-cookie-remove-all'

/**
 * set cookie
 * @param cookie's key
 * @param cookie's value
 * @param cookie's expires its default value is 2 days.
 */
export function setCookie(key, value, expires = 2) {
    jsCookie.set(key, value, {expires: Number(expires)})
}

/**
 * get cookie by key
 * @param cookie's key
 * @returns cookie's value
 */
export function getCookie(key) {
    return jsCookie.get(key)
}

/**
 * remove cookie by key
 * @param cookie's key
 * @returns cookie's value
 */
export function removeCookie(key) {
    return jsCookie.remove(key)
}

/**
 * set token to cookie
 * @param token
 * @param expires
 */
export function setCookieToken(value, expires = 1) {
    setCookie('token', value, expires)
}

/**
 * get token from cookie
 * @returns token
 */
export function getCookieToken() {
    return getCookie('token')
}

/**
 * remove token from cookie
 * @returns token
 */
export function removeCookieToken() {
    return removeCookie('token')
}

/**
 * remove all cookies.
 */
export function removeAllCookies() {
    jsCookieExtension.removeAll()
}