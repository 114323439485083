<template>
  <div>
    <div class="trackItemTitle">{{ props.itemTitle }}</div>
    <div class="trackItemValue">{{ props.itemValue }}</div>
  </div>
</template>

<script setup>
import {defineProps} from "vue"

const props = defineProps({
  itemTitle: String,
  itemValue: String
})
</script>

<style lang="scss" scoped>
</style>