<template>
  <a-layout class="main">
    <a-layout-sider class="leftSide">
      <div style="margin-top: -40px; margin-left: -30px; margin-bottom: 20px;">
        <img src="../assets/logo.svg" alt="logo">
      </div>
      <LeftSide/>
    </a-layout-sider>
    <a-layout>
      <a-layout-header class="mainHeader" >
        <div class="headerContainer">
          <MainHeader/>
        </div>
      </a-layout-header>
      <a-layout-content class="mainContent">
        <div class="contentContainer">
          <router-view/>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script lang="js">
import { defineComponent } from "vue"
import MainHeader from "@/components/MainHeader.vue"
import LeftSide from "@/components/LeftSide.vue"

export default defineComponent({
  name: 'MainLayout',
  components: {
    MainHeader,
    LeftSide
  }
})
</script>

<style lang="scss" scoped>
@import "@/style/design.scss";
.main{
  height: 100vh;
}
.leftSide {
  background: #F5F5F5;
  box-shadow: 0 66px 19px 0 rgba(227, 227, 227, 0.01), 0 42px 17px 0px rgba(227, 227, 227, 0.06), 0 24px 14px 0 rgba(227, 227, 227, 0.21), 0 11px 11px 0 rgba(227, 227, 227, 0.36), 0 3px 6px 0 rgba(227, 227, 227, 0.41);
  flex: 0 0 260px !important;
  max-width: 260px !important;
  min-width: 260px !important;
  width: 260px !important;
  padding-left: 37px;
  padding-top: 48px;
}
.mainHeader.ant-layout-header {
  height: 80px;
  line-height: 80px;
  background-color: $main-background-color;
  padding: 0;
  .headerContainer {
    background: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(10px);
    height: 100%;
    padding: 0 35px 0 35px;
    border-radius: 0 0 10px 0;
  }
}
.mainContent {
  background-color: $main-background-color;
  padding: 26px;
  .contentContainer {
    background: #FFF;
    border-radius: 10px;
    height: 100%;
    overflow: auto;
    padding: 26px;
  }
}
</style>
