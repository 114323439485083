import dayjs from "dayjs"
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export function formUtcToLocal(utcString, formatString) {
    return dayjs.utc(utcString).local().format(formatString)
}

export function convertUTCToLocal(utcString) {
    const date = new Date(utcString);
    return date.toLocaleString();
}