import translationAxios from "axios"
import {serverConfig} from "@/hooks/serverConfig"

const translationRequest = translationAxios.create({
    baseURL: process.env.NODE_ENV !== 'production' ? '' : serverConfig.translationUrl,
    contentType: 'application/json',
    timeout: 60 * 1000
})

translationRequest.interceptors.request.use(config => {
    return config
}, error => {
    return Promise.reject(error)
})
translationRequest.interceptors.response.use(res => {
    const data = res.data;
    console.log(res);
    return data
}, error => {
    return Promise.reject(error)
})

export default translationRequest